<template>
  <FormComponent class="register" @submit="register">
    <h3 class="auth-title">
      <button class="btn" type="button" @click="back()">
        <span class="material-icons-outlined">arrow_back</span>
      </button>
      Cadastro Teste Grátis de 30 Dias
    </h3>
    <div class="row">
      <div class="col-md-6">
        <InputComponent :label="$tc('app.name', 1)" name="name" :placeholder="$tc('app.name', 1)" required v-model="form.name"></InputComponent>
      </div>
      <div class="col-md-6">
        <InputComponent :label="$tc('app.company', 1)" name="company" :placeholder="$t('app.company')" required v-model="form.account_name"></InputComponent>
      </div>
      <div class="col-md-6">
        <SelectComponent :label="$tc('app.agent', 2)" :placeholder="$tc('app.agent', 2)" :options="agents" required v-model="form.agents"></SelectComponent>
      </div>
      <div class="col-md-6">
        <InputComponent name="email" type="email" :label="$tc('app.email', 1)" :placeholder="$t('app.email')" required v-model="form.email"></InputComponent>
      </div>
      <div class="col-md-6">
        <TelInputComponent :label="$tc('app.phone', 1)" v-model="form.mobile_number" required></TelInputComponent>
      </div>
      <div class="col-md-6">
        <PasswordInputComponent :label="$tc('app.password', 1)" :placeholder="$t('login-invite.fullname')" required v-model="form.password"></PasswordInputComponent>
      </div>
    </div>
    <CheckboxComponent v-model="terms" required
      >Li e concordo com os
      <a id="show-btn" href="javascript:void(0)" @click="showModal()">termos de uso</a>
      <b-modal ref="my-modal" size="xl" scrollable hide-footer title="Termos">
        <div class="container-fluid">
          <ModalContentTerms />
        </div>
      </b-modal>
    </CheckboxComponent>
    <ButtonComponent type="submit" is-block :loading="isSending">{{ $t('app.register') }}</ButtonComponent>

    <!--<div class="mb-4" v-if="isMainWhitelabel">
      <SeparatorComponent text="Ou continue com" />
      <div class="d-flex align-items-center justify-content-center social-group">
        <GoogleButtonComponent @login="handleGoogleLogin"></GoogleButtonComponent>
        <FacebookButtonComponent @login="handleFacebookLogin"></FacebookButtonComponent>
      </div>
    </div>-->
  </FormComponent>
  <!--</form>-->
</template>

<script>
// @ is an alias to /src
import User from '@/models/user';
import { load } from 'recaptcha-v3';
import ModalContentTerms from '@/components/rebranding/ModalContentTerms.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import FormComponent from '../../components/form/FormComponent.vue';
import InputComponent from '../../components/form/InputComponent.vue';
import TelInputComponent from '../../components/form/TelInputComponent.vue';
import PasswordInputComponent from '../../components/form/PasswordInputComponent.vue';
import CheckboxComponent from '../../components/form/CheckboxComponent.vue';
import ButtonComponent from '../../components/ui/ButtonComponent.vue';
import SeparatorComponent from '../../components/ui/SeparatorComponent.vue';
import GoogleButtonComponent from '../../components/ui/GoogleButtonComponent.vue';
import FacebookButtonComponent from '../../components/ui/FacebookButtonComponent.vue';

export default {
  name: 'Register',
  components: {
    ModalContentTerms,
    FormComponent,
    InputComponent,
    TelInputComponent,
    PasswordInputComponent,
    CheckboxComponent,
    ButtonComponent,
    // SeparatorComponent,
    // GoogleButtonComponent,
    // FacebookButtonComponent,
    SelectComponent,
  },
  computed: {
    isMainWhitelabel() {
      return this.$store.state?.admin?.whitelabel?.['is_main'] || !this.$store.state?.whitelabel;
    },
  },
  data() {
    return {
      agents: [
        {
          text: '00 a 04',
          value: '00 a 04',
        },
        {
          text: '05 a 10',
          value: '05 a 10',
        },
        {
          text: '11 a 20',
          value: '11 a 20',
        },
        {
          text: 'Acima de 20',
          value: 'Acima de 20',
        },
      ],
      user: new User('', ''),
      terms: false,
      isSending: false,
      isSendingGoogle: false,
      isSendingFacebook: false,
      form: {
        name: '',
        account_name: '',
        email: '',
        mobile_number: '',
        password: '',
        agents: '',
      },
    };
  },
  methods: {
    back() {
      this.$parent.routing = false;
      this.$router.push('/login');
    },
    recaptcha(callback) {
      load(process.env.VUE_APP_RECAPTCHA_KEY, {
        useRecaptchaNet: true,
        autoHideBadge: true,
      }).then((recaptcha) => {
        recaptcha.execute('refresh').then((token) => {
          console.log(token);
          this.user.recaptcha_token = token;
          callback();
        });
      });
    },
    register() {
      this.isSending = true;
      this.recaptcha(() => {
        this.$store.dispatch('auth/registerTrial', { ...this.form, ...this.$route.query }).then(
          () => {
            this.$store.dispatch('auth/login', this.form).then(
              (response) => {
                console.log(response);
                this.isSending = false;
                if (this.$store.state.admin.whitelabel.name === 'Yup Chat') {
                  if (window.dataLayer) {
                    window.dataLayer.push({ event: 'registered' });
                  }
                }
                this.$router.push('/');
              },
              (error) => {
                this.$toast.show({
                  title: this.$t('sms.infos.filters.status.lbl-error'),
                  content: error.message,
                  type: 'danger',
                });
                this.isSending = false;
              },
            );
          },
          (error) => {
            console.log(error);
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        );
      });
    },
    showModal() {
      this.$refs['my-modal'].show();
    },
    hideModal() {
      this.$refs['my-modal'].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn');
    },
    handleGoogleLogin(response) {
      this.$store.dispatch('auth/loginWithGoogle', response).then(() => {
        this.$live.firstLaunch = true;
        this.$router.push('/');
      });
    },
    handleFacebookLogin(response) {
      this.$store.dispatch('auth/loginWithFacebook', response).then(() => {
        this.$live.firstLaunch = true;
        this.$router.push('/');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.social-group {
  gap: 16px;
}

.auth-title {
  font-size: 36px;
  margin-bottom: 26px;

  .btn {
    padding: 0;
    font-size: 24px;
  }
}
</style>
